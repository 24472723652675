// 3rd-party Plugins
@import "~highlight.js/styles/googlecode.css";
@import "~perfect-scrollbar/css/perfect-scrollbar.css";
// Theme Styles
@import "./_metronic/_assets/sass/style.react.scss";
// Default Layout themes
@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
@import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Header themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/base/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/base/dark.scss";
// Header Menu themes
// Light
//@import "./_metronic/_assets/sass/themes/layout/header/menu/light.scss";
// Dark
// @import "./_metronic/_assets/sass/themes/layout/header/menu/dark.scss";
// Brand themes
.brand-dark {
    @import "./_metronic/_assets/sass/themes/layout/brand/dark.scss";
}

// Light
.brand-light {
    @import "./_metronic/_assets/sass/themes/layout/brand/light.scss";
}

// Aside themes
// Dark
//@import "./_metronic/_assets/sass/themes/layout/aside/dark.scss";
// Light
// @import "./_metronic/_assets/sass/themes/layout/aside/light.scss";
// .example-highlight {
//   padding: 0rem 1.5rem 1.75rem !important;
// }
.example-highlight>pre {
    background: none transparent !important;
    margin: 0 !important;
    padding-top: 0.5rem;
    code {
        overflow-y: auto;
        display: block;
        span {
            background: none transparent !important;
        }
    }
}

.json>pre {
    background: none #fff !important;
}

.example-code>.json {
    background: none #fff !important;
}

.symbol.symbol-45>svg {
    width: 100%;
    max-width: 45px;
    height: 45px;
}

.react-bootstrap-table {
    overflow-x: auto;
}

.react-bootstrap-table {
    th {
        outline: none;
        &.sortable {
            .svg-icon-sort {
                opacity: 0;
            }
            &:hover {
                cursor: pointer;
                .svg-icon-sort {
                    opacity: 1;
                }
            }
        }
    }
    .table.table-head-custom thead tr {
        .sortable-active {
            color: $primary !important;
        }
    }
}

.cursor-default {
    cursor: default !important;
}

.footer-div {
    padding: 2rem 2.25rem;
    background-color: #ffffff;
    border-top: 1px solid #ebedf3;
}

.toaster {
    width: 300px;
}

$primary-color: #1d3557;
$error-color: #e63946;
.input-error {
    border-color: $error-color;
}

.error {
    color: $error-color;
    font-size: 1rem;
    margin-top: 0.3rem;
}

.pagination-fab {
    list-style-type: none;
}

.count-span {
    display: inline-block;
    margin: 7px 10px;
    font-size: 20px;
}

.modal .modal-header .close {
    color: black;
}

.modal .modal-header .close span {
    display: flex;
}

.teal-color {
    color: #1BC5BD !important;
}

.row-no-margin {
    margin-left: 0;
    margin-right: 0;
}

.row-no-padding {
    padding-left: 0;
    padding-right: 0;
}

.required:after {
    content: "*";
    // font-weight: bold;
    color: red;
}

.disabled-select {
    background-color: grey !important;
    pointer-events: none;
    border-color: grey !important;
}

.disabled-text {
    pointer-events: none;
}

.center-cropped {
    object-fit: cover;
}

// .rounded-circle {
//     background: lightgrey;
//     position: inherit;
//     padding: 5px;
//     box-shadow: 0 5px 25px 0 rgba(0, 0, 0, 0.2);
// }
.wwGfR {
    background: transparent !important;
}

.hjFLZP {
    background: transparent !important;
}

// .sWWsi {
//     display: block !important;
// }
.line-separator {
    border-left: 1px solid lightgray;
    height: 38px;
}

.listing-image {
    max-width: 100px;
    max-height: 60px;
    padding: 2px !important;
}

.font-18 {
    font-size: 18px;
}

.theme-color {
    color: #2ba6cb !important;
}

.theme-orange-color {
    color: #FF7E5D !important;
}

.infobox-5 {
    // min-height: 150px;
    margin-bottom: 20px;
    .card-icon {
        i {
            font-size: 4rem;
        }
    }
}

.listing-count {
    cursor: pointer;
    color: #3D9CFC !important;
}

.listing-count:hover,
.listing-count:active {
    color: #3D9CFC !important;
    text-decoration: underline;
}

.dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
}

.filter-block {
    border: 1px solid darkgrey;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: 0px;
    border-radius: 5px;
}

.sup {
    font-size: large;
    // vertical-align: super;
}

//reports css
.rep-font-size {
    font-size: 20px;
    padding: 7px;
    color: #003399;
    text-align: center;
}

.rep-table {
    width: 100%;
}

.signin-first-col {
    width: 40%;
    padding-left: 40px;
}

.rep-table-row {
    border-bottom: 1px dashed #ccc;
}

.signin-username {
    font-size: 20px;
}

.rep-email {
    font-size: 15px;
    color: #012790;
    text-decoration: underline;
    font-weight: bold;
}

.rep-email:hover {
    color: #666666;
    text-decoration: none;
    cursor: pointer;
}

.signin-signature {
    width: 60%;
    padding-top: 29px;
    padding-left: 20px !important;
}

.rep_event_name {
    font-size: 20px;
    padding: 5px;
    color: #003399;
    text-align: center;
    font-weight: bold;
    margin-top: 20px;
}

.rep-report {
    max-width: 61%;
    margin-left: auto;
    margin-right: auto;
}

.roster-header {
    font-size: 14px;
    color: #012790;
    text-decoration: underline;
    border-top: 1px dashed #ccc;
    background-color: whitesmoke;
    padding-left: 5px;
    padding-top: 10px;
    padding-bottom: 12px;
}

.roster-header:hover {
    color: #666666;
    text-decoration: none;
    cursor: pointer;
}

.f-13 {
    font-size: 13px;
}

.rep-col-width {
    min-width: 140px;
    max-width: 140px;
}

.rep-button {
    border: 1px solid #555;
    background: #FDF1CB;
    font-size: 11px;
    padding: 2px 5px;
    font-weight: bold;
    color: #444;
    cursor: pointer;
    outline: none;
    text-decoration: none;
    margin-top: 40px;
    margin-bottom: 40px;
}

.wrapperrr {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.bg-white {
    background-color: white;
}

.text-wrap {
    word-break: break-all;
}

@media print {
    #printPageButton {
        display: none;
    }
}

select {
    background: url("/media/svg/icons/down-arrow.svg") no-repeat right #ddd;
    -webkit-appearance: none;
    background-position-x: 95%;
    background-size: 12px;
}

.table-hover tbody tr:hover td {
    background: #F3F6F9 !important;
    background-color: #F3F6F9;
}

.excel-icon {
    color: #358795;
}

.custom-field-div {
    background-color: #F3F6F9 !important;
    border-radius: 0.42rem;
    min-height: 55px;
}

.image-loading-spinner {
    top: 34px !important;
    left: 23px !important;
}

.react-bootstrap-table {
    .sort-desc {
        box-shadow: none !important;
        &:before {
            content: "▼";
            float: right;
        }
    }
    .sort-asc {
        box-shadow: none !important;
        &:before {
            content: "▲";
            float: right;
        }
    }
}

.text-decoration-underline {
    text-decoration: underline;
}

.modal-custom-width {
    width: 70vw;
    max-width: 70vw;
}