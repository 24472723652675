.select__control {
    background-color: transparent !important;
    border-style: hidden !important;
    border-width: 0px !important;
    cursor: pointer !important;
}

.select__control:hover,
.select__control:not(:hover) {
    border-color: transparent !important;
    box-shadow: 0px 0px 0px 0px !important;
}

.add-text {
    font-weight: 500;
    cursor: pointer;
}

.custom-selected-div {
    background-color: #F3F6F9;
}

.export-div:hover {
    background-color: lightgray;
}