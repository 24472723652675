.card .header {
    color: #555;
    padding: 15px;
    position: relative;
}

.card .body {
    font-size: 14px;
    color: #555;
    padding: 15px;
}