.add-location {
    font-weight: 500;
    cursor: pointer;
}

.location-div {
    background-color: #F3F6F9;
}

.caret {
    caret-color: transparent; //no blinking cursor
}